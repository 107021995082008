import {ApiResponse, FetchProps} from "./Types";
import {fetchFunction} from "./fetchFunction";
import {Contact} from "./fetchContacts";

export type Team = {
    id: number;
    full_name: string;
    role: string;
    qualifications: string;
    bio: string;
    full_profile_photo_url: string;
    contacts: Contact[];
}

export function fetchTeam({setLoading, setData, afterDone, fetchData}: FetchProps<Team[]>) {
    setLoading?.(true);
    fetchFunction<ApiResponse<Team[]>>({path: 'members', fetchData}).then(response => {
        setData(response.data);
        setLoading?.(false);
        afterDone?.(response);
    });
}
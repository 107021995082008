import {Skeleton, SkeletonProps, Box} from "@mui/material";
import React from "react";

export type LoadingProps = {
    children?: JSX.Element;
    loading: boolean;
    repeat?: number;
} & SkeletonProps;

function getDefaultStyles({repeat = 1, children, loading, ...props}: LoadingProps): SkeletonProps {
    props.variant = props.variant || 'text';
    switch (props.variant) {
        default:
            props.width = props.width || '85%';
            if (props.variant !== 'text') props.height = props.height || 12;
            if (repeat > 1) props.sx = {mb: 1, ...props.sx};
            if (props.variant === 'text') props.sx = {fontSize: '1rem', ...props.sx};
            break;
    }
    return props;
}

export default function Loading(loadingProps: LoadingProps) {
    const {loading = false, children, repeat = 1} = loadingProps;
    return loading ? (
        <Box>
            {Array.from(Array(repeat)).map((_i, i) => <Skeleton
                key={`skeleton-${i}`} {...getDefaultStyles(loadingProps)}/>)}
        </Box>
    ) : (children || null);
}
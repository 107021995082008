import { Box } from '@mui/material';
import SwiperCore, { Keyboard, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getClients } from '../pages/Clients';

export function SwiperClients() {
    SwiperCore.use([Keyboard, Autoplay]);
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'calc(100vw - 2rem)' }}>
            <Swiper slidesPerView='auto' spaceBetween={10} autoplay keyboard speed={1000} className='clients-swiper'>
                {getClients().map((client, index) => (
                    <SwiperSlide key={index}>
                        <Box component='img' src={client} sx={{ width: '5rem', height: '5rem', mx: 'auto', objectFit: 'contain' }} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
}
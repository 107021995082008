import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Link, Modal, Typography } from '@mui/material';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import { fetchProjects, Project } from '../api/fetchProjects';
import Loading from '../components/Loading';
import { orange } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { getAboutUs, getValues } from './About';
import { DEFAULTS } from '../common/Defaults';
import ProjectBreadCrumb from '../components/ProjectBreadCrumb';
import Single from '../components/Project';
import FeaturedProjects from '../components/FeaturedProjects';
import { SwiperClients } from '../components/SwiperClients';

export default function Home() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Project[]>([]);
    const [project, setProject] = React.useState<Project | undefined>();
    React.useEffect(() => {
        setData([]);
        fetchProjects({ setLoading, setData, fetchData: { limit: 6 } });
    }, []);

    const after = {
        content: "''",
        bgcolor: 'grey.900',
        opacity: 0.3,
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0, left: 0
    };

    const projectModal = (<Modal
        open={project !== undefined}
        onClose={() => setProject(undefined)}
    >
        <Box sx={DEFAULTS.modalStyle}>
            {project !== undefined && <ProjectBreadCrumb {...project} closeModal={() => setProject(undefined)} />}
            <Divider sx={{ mt: 2 }} />
            {project !== undefined && <Single {...project} />}
        </Box>
    </Modal>);

    return (
        <Box position='relative'>
            <Box sx={{ position: 'absolute', right: 10, top: 10, zIndex: 2 }}>
                <Loading loading={loading} width={100} height={62} variant='rounded'>
                    <Box component='img' sx={{ width: 100, height: 'auto', boxShadow: 4, borderRadius: 1, bgcolor: 'grey.50' }} src='https://www.civilone.co.ke/wp-content/uploads/2021/05/ISO_2-removebg-preview1.png' />
                </Loading>
            </Box>

            <Loading loading={loading} variant='rectangular' width='100%' height={537}>
                <>
                    <Carousel indicators={false} duration={2500} interval={5000}>
                        {data?.slice(0, 4)?.map(project => (
                            <Box key={`dashboard-carousel-image-${project.id}`} onClick={() => setProject(project)} sx={{ cursor: 'pointer' }}>
                                <Box sx={{ '&:after': after, position: 'relative' }}>
                                    <Box sx={{ width: '100%', height: 500, objectFit: 'cover' }} component='img' src={project.images?.[0]?.full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'} />
                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', color: 'primary.light', zIndex: 1 }}>
                                        <Typography component='div' variant='overline' textAlign='center' fontWeight={700} lineHeight={1.1} fontSize='3rem'>Civil One</Typography>
                                        <Typography component='div' variant='overline' textAlign='center' fontWeight={700}>Consulting Engineers</Typography>
                                        <Typography component='div' variant='overline' textAlign='center' fontWeight={700} color={orange[700]}>We are ISO 9001 Certified</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ bgcolor: 'primary.main', textAlign: 'center' }} >
                                    <Typography component='div' variant='overline' sx={{ color: 'white', fontSize: '0.7rem' }} noWrap>{project.project_name}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Carousel>
                    {(!data || data.slice(0, 4).length === 0) && (
                        <Box sx={{ '&:after': after, position: 'relative' }}>
                            <Box sx={{ width: '100%', height: 500, objectFit: 'cover' }} component='img' src='https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png' />
                            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', color: 'primary.light', zIndex: 1 }}>
                                <Typography component='div' variant='overline' textAlign='center' fontWeight={700} lineHeight={1.1} fontSize='3rem'>Civil One</Typography>
                                <Typography component='div' variant='overline' textAlign='center' fontWeight={700}>Consulting Engineers</Typography>
                                <Typography component='div' variant='overline' textAlign='center' fontWeight={700} color={orange[700]}>We are ISO 9001 Certified</Typography>
                            </Box>
                        </Box>
                    )}
                </>
            </Loading>

            <Grid2 disableEqualOverflow={true} container sx={{ mt: 1 }}>
                {getValues().map((_, index) => (
                    <Grid2 xs={12} sm={4} key={index}>
                        <Loading loading={loading} variant='rectangular' width='100%' height={37}>
                            <Box sx={{ bgcolor: _.color, textAlign: 'center' }}>
                                <Typography variant='overline' sx={{ color: 'white', fontSize: '0.9rem' }}>{_.title}</Typography>
                            </Box>
                        </Loading>
                    </Grid2>
                ))}
            </Grid2>

            <Grid2 disableEqualOverflow={true} container sx={{ mt: 4 }} alignItems='center'>
                <Grid2 xs={12} sm={6}>
                    <Loading loading={loading} sx={{ fontSize: '2.1rem' }} width={130}>
                        <Typography variant='h6' textTransform='uppercase'>Who We Are</Typography>
                    </Loading>
                    <Divider
                        sx={{ mb: 2, borderColor: 'primary.light', borderWidth: 2, maxWidth: 75 }} />

                    <Loading loading={loading} variant='rectangular' height={190} width='100%'>
                        <>{getAboutUs(1)}</>
                    </Loading>

                    <Loading loading={loading} width={100} height={35} variant='rounded' sx={{ mt: 2 }}>
                        <Link href='/about' sx={{ textDecoration: 'none' }}>
                            <Button variant='contained' sx={{ mt: 2 }}>Read More</Button>
                        </Link>
                    </Loading>
                </Grid2>
                <Grid2 xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'block' }, textAlign: 'center', mb: 4 }}>
                    <Box sx={{ height: 350 }} component='img' src={'https://www.civilone.co.ke/wp-content/uploads/2021/05/construction-2824556_640-1.png'} />
                </Grid2>
            </Grid2>

            <>
                <Loading loading={loading} sx={{ fontSize: '2.1rem', mt: 4 }} width={200}>
                    <Typography variant='h6' textTransform='uppercase' sx={{ mt: 3 }}>Featured Projects</Typography>
                </Loading>
                <Divider
                    sx={{ mb: 2, borderColor: 'primary.light', borderWidth: 2, maxWidth: 75 }} />

                <FeaturedProjects setProject={setProject} parentLoading={loading} parentData={data} limit={6} />

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Loading loading={loading} width={200} height={35} variant='rounded' sx={{ mt: 2 }}>
                        <Link href='/projects' sx={{ textDecoration: 'none' }}>
                            <Button variant='contained' sx={{ mt: 2 }}>View All Projects</Button>
                        </Link>
                    </Loading>
                </Box>
            </>

            <>
                <Loading loading={loading} sx={{ fontSize: '2.1rem', mt: 4 }} width={130}>
                    <Typography variant='h6' textTransform='uppercase' sx={{ mt: 3 }}>Testimonials</Typography>
                </Loading>
                <Divider
                    sx={{ mb: 2, borderColor: 'primary.light', borderWidth: 2, maxWidth: 75 }} />

                <Grid2 disableEqualOverflow={true} container spacing={5}>
                    <Grid2 xs={12} sm={6}>
                        <Loading loading={loading} variant='rectangular' height={200} width='100%'>
                            <Card variant='outlined'>
                                <CardHeader avatar={<Avatar src='https://www.civilone.co.ke/wp-content/uploads/2021/06/Symbion2-2.png' />} title='Symbion' subheader='One of our clients!' />
                                <CardContent>
                                    <Typography variant='caption'>
                                        We are pleased to recommend Civil One Consulting Engineers Ltd for provision of Civil and structural engineering consultancy services. We have had opportunity to work with the firm in various projects and found their performance to be outstanding.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Loading>
                    </Grid2>
                    <Grid2 xs={12} sm={6}>
                        <Loading loading={loading} variant='rectangular' height={200} width='100%'>
                            <Card variant='outlined'>
                                <CardHeader avatar={<Avatar src='https://www.civilone.co.ke/wp-content/uploads/2021/06/Kiri-Consult-Ltd-3.jpg' />} title='Kiri Consult' subheader='Another one of our clients!' />
                                <CardContent>
                                    <Typography variant='caption'>
                                        We have worked with Civil one consulting engineers in Highway Engineering consultancy and they provided outstanding consultancy services in preparing geometric design, ancillary features and provision of cross sections drawings.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Loading>
                    </Grid2>
                </Grid2>
            </>

            <Box sx={{ my: 4 }}>
                <SwiperClients />
            </Box>

            {projectModal}
        </Box>
    );
}
import Box from "@mui/material/Box";
import {Divider, Link, Typography} from "@mui/material";
import {Team} from "../api/fetchTeam";
import { getLink, Icon } from "../api/fetchContacts";

export default function TeamMember(teamMember: Team) {
    return (
        <Box>
            <Box component='img'
                sx={{width: 250, height: 300, objectFit: 'cover', boxShadow: 4, mt: 1, float: 'left', mr: 2, display: {xs: 'none', sm: 'block'}}}
                src={teamMember.full_profile_photo_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}/>
            <Typography variant='h6' fontWeight={500} mt={1} lineHeight={1}>
                {teamMember.full_name}
            </Typography>
            <Typography variant='overline'>{teamMember.role || 'Member'}</Typography><br />
            {teamMember.qualifications && <Typography variant='caption' sx={{color: 'grey.600'}}>{teamMember.qualifications}</Typography>}
            <Box sx={{display: {xs: 'flex', sm: 'none', mt: 1, justifyContent: 'center'}}}>
                <Box component='img'
                    sx={{width: 250, height: 300, boxShadow: 10, objectFit: 'cover'}}
                    src={teamMember.full_profile_photo_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}/>
            </Box>
            {teamMember.bio && <Typography variant='body2' mt={3} textAlign='justify'>{teamMember.bio}</Typography>}
            <Divider sx={{my: 2}}/>
            {teamMember.contacts.filter(({contact_type_id}) => contact_type_id !== 8).length === 0 && (
                <Typography variant='subtitle2' sx={{textAlign: 'center'}}>
                    Team Member has NO Contact Info!
                </Typography>
            )}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 3,
                flexWrap: {xs: 'wrap', sm: 'noWrap'}
            }}>
                {teamMember.contacts.filter(({contact_type_id}) => contact_type_id !== 8).map(contact => (
                    <Link href={getLink(contact)} key={contact.id}>
                        <Icon {...contact}/>
                    </Link>
                ))}
            </Box>
        </Box>
    );
}

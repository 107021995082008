import { ApiResponse, FetchProps, SaveProps } from "./Types";
import { fetchFunction } from "./fetchFunction";
import { Phone, Facebook, Instagram, YouTube, Mail, LinkedIn, Twitter } from "@mui/icons-material";

export type Email = {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export type EmailResponse = {
  errors?: { [k in keyof Email]: string[] };
  message?: string;
  success?: boolean;
}

export type Contact = {
  id: number;
  contact_type_id: number;
  contact: string;
}

export function Icon({ contact_type_id }: Contact) {
  switch (contact_type_id) {
    case 1:
      return <Phone />;
    case 3:
      return <Facebook />;
    case 4:
      return <Instagram />;
    case 5:
      return <Twitter />;
    case 6:
      return <LinkedIn />;
    case 7:
      return <YouTube />;
    default:
      return <Mail />;
  }
}

export function getLink({ contact_type_id, contact }: Contact) {
  switch (contact_type_id) {
    case 1:
      return `tel:${contact}`;
    case 2:
      return `mailto:${contact}`;
    default:
      return contact;
  }
}

export function fetchContacts({ setLoading, setData, afterDone, fetchData }: FetchProps<Contact[]>) {
  setLoading?.(true);
  fetchFunction<ApiResponse<Contact[]>>({ path: 'contacts', fetchData }).then(response => {
    setData(response.data);
    setLoading?.(false);
    afterDone?.(response);
  });
}

export function sendEmail({ setSubmitting, setResponse, afterDone, body }: SaveProps<EmailResponse>) {
  setSubmitting?.(true);
  setResponse(undefined);
  fetchFunction<EmailResponse>({ path: 'send_email', method: 'post', fetchData: body }).then(response => {
    setResponse(response);
    setSubmitting?.(false);
    afterDone?.(response);
  });
}
import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {Service as ServiceSingle} from "../api/fetchServices";

export default function Service(service: ServiceSingle) {
    return (
        <Grid2 disableEqualOverflow={true} container spacing={5} alignItems='center'>
            <Grid2 xs={12} sm={6} mt={1}>
                <Box component='img'
                     sx={{width: '100%', height: 'auto', maxHeight: 660, boxShadow: 1, my: 1}}
                     src={service.images?.[0]?.full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}/>

                {Array.from(Array(Math.ceil((service.images.length - 1) / 2.0))).map((_, i) => (
                    <Box sx={{display: 'flex', gap: 1, mb: 1}} key={`service-image-${i}`}>
                        <Box component='img'
                             sx={{width: '50%', height: 'auto', maxHeight: 660, boxShadow: 1, ...(service.images?.[i * 2 + 2] && {flexGrow: 1})}}
                             src={service.images[i * 2 + 1].full_image_url}/>
                        {service.images?.[i * 2 + 2] &&
                            <Box component='img' src={service.images[i * 2 + 2].full_image_url}
                                 sx={{width: '50%', height: 'auto', maxHeight: 660, boxShadow: 1, flexGrow: 1}}/>}
                    </Box>
                ))}
            </Grid2>
            <Grid2 xs={12} sm={6}>
                <Typography variant='h6' fontWeight={500} mt={1} lineHeight={1}>
                    {service?.service_name}
                </Typography>
                <Typography variant='body2' mt={3} dangerouslySetInnerHTML={{__html: service.description || 'No Description has been added for this Service!'}}/>
            </Grid2>
        </Grid2>
    );
}

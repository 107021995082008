import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {Button, Divider, Modal, Typography} from "@mui/material";
import {DEFAULTS} from "../common/Defaults";
import ServiceBreadCrumb from "../components/ServiceBreadCrumb";
import Single from "../components/Service";
import Loading from "../components/Loading";
import {fetchServices, getDescription, Service} from "../api/fetchServices";
import { useLocation } from 'react-router-dom';

export default function Services() {
    const {hash} = useLocation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Service[]>([]);
    const [serviceID, setServiceID] = React.useState<number>();
    React.useEffect(() => {
        setData(Array.from(Array(6)));
        fetchServices({setLoading, setData});
    }, []);

    React.useEffect(() => {
        if (hash && data && !loading) {
            const splitIndex = hash.split('-');
            const index = parseInt(splitIndex[splitIndex.length - 1]);
            if (!isNaN(index)) setServiceID(index);
        }
    }, [loading, data, hash]);

    const modal = (<Modal
        open={serviceID !== undefined}
        onClose={() => setServiceID(undefined)}
    >
        <Box sx={DEFAULTS.modalStyle}>
            {serviceID !== undefined && data[serviceID] && <ServiceBreadCrumb {...data[serviceID]} closeModal={() => setServiceID(undefined)}/>}
            <Divider sx={{mt: 2}}/>
            {serviceID !== undefined && data[serviceID] && <Single {...data[serviceID]}/>}
        </Box>
    </Modal>);
    return (
        <Box>
            {(!data || data.length === 0) && !loading && (
                <Typography variant='h6' sx={{textAlign: 'center', my: 3}}>No Services Available!</Typography>
            )}
            <Grid2 disableEqualOverflow={true} container spacing={5}>
                {data?.map((service, index) => (
                    <Grid2 xs={12} sm={6} md={4} lg={3} key={`service-${service?.id || index}`}
                           sx={{display: 'flex', flexDirection: 'column'}}>
                        <Loading loading={loading} variant='rectangular' width='100%' height={250}>
                            <Box onClick={() => setServiceID(index)} component='img'
                                 sx={{width: '100%', height: 250, objectFit: 'cover', boxShadow: 1, cursor: 'pointer'}}
                                 src={service?.images?.[0]?.full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}/>
                        </Loading>
                        <Loading loading={loading} sx={{fontSize: '1.8rem'}} width='100%'>
                            <Typography variant='h6' sx={{color: 'grey.900', fontSize: '1rem'}} fontWeight={500} mt={1}>
                                {service?.service_name}
                            </Typography>
                        </Loading>
                        <Loading loading={loading}>
                            <Typography variant='body2' sx={{color: 'grey.700', fontSize: '0.8rem'}}
                                        dangerouslySetInnerHTML={{__html: getDescription(service)}}/>
                        </Loading>
                        <Box sx={{flexGrow: 1, my:1}}/>
                        <Loading loading={loading} width={150} height={50} sx={{float: 'right'}}>
                            <Box alignSelf='flex-end'>
                                <Button onClick={() => setServiceID(index)} variant='contained'>
                                    View Service Detail
                                </Button>
                            </Box>
                        </Loading>
                    </Grid2>
                ))}
            </Grid2>
            {modal}
        </Box>
    );
}
import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Divider, Modal, Typography} from "@mui/material";
import GalleryImageBreadCrumb from "../components/GalleryImageBreadCrumb";
import {useState} from "react";
import {DEFAULTS} from "../common/Defaults";
import {fetchGallery, Gallery as GalleryType} from "../api/fetchGallery";
import Loading from "../components/Loading";
import Carousel from 'react-material-ui-carousel';

export default function Gallery() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<GalleryType[]>([]);
    const [galleryID, setGalleryID] = useState<number>();
    React.useEffect(() => {
        setData(Array.from(Array(6)));
        fetchGallery({setLoading, setData});
    }, []);

    const modal = (<Modal
        open={galleryID !== undefined}
        onClose={() => setGalleryID(undefined)}
    >
        <Box sx={DEFAULTS.modalStyle}>
            {galleryID !== undefined && data[galleryID] && <GalleryImageBreadCrumb {...data[galleryID]} closeModal={() => setGalleryID(undefined)}/>}
            <Divider sx={{my: 2}}/>
            {galleryID !== undefined &&
                <Typography variant='body2' mb={3} dangerouslySetInnerHTML={{__html: data[galleryID]?.description}}/>}
            {galleryID !== undefined && <Carousel duration={1000} interval={5000} navButtonsAlwaysVisible={!loading}>
                {data[galleryID]?.images.map(({id, full_image_url, caption}) => (
                    <Box key={`gallery-image-${id}`} sx={{position: 'relative'}}>
                        <Box sx={{ width: '100%', height: 'auto', maxHeight: 700, objectFit: 'cover', bgcolor: 'grey.200', borderRadius: 2 }} component='img' src={full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'} />
                        {caption && <Box sx={{ position: 'absolute', bottom: 20, marginRight: 5, marginLeft: 5, bgcolor: 'rgba(0,0,0,0.4)', borderRadius: 2}}>
                            <Typography component='div' variant='caption' sx={{ color: 'white', padding: 1 }}>{caption}</Typography>
                        </Box>}
                    </Box>
                ))}
            </Carousel>}
        </Box>
    </Modal>);
    return (
        <Box>
            {(!data || data.length === 0) && !loading && (
                <Typography variant='h6' sx={{textAlign: 'center', my: 3}}>No Gallery Images Available!</Typography>
            )}
            <Grid2 disableEqualOverflow={true} container spacing={3} justifyContent='center' alignItems='center'>
                {data?.map((gallery, index) => (
                    <Grid2 xs={12} sm={6} md={4} lg={3} key={`gallery-${gallery?.id || index}`}>
                        <Box sx={{cursor: 'pointer'}} onClick={() => setGalleryID(index)}>
                            <Loading loading={loading} sx={{fontSize: '1.7rem'}} width={200}>
                                <Typography variant='h6'>{gallery?.gallery_name}</Typography>
                            </Loading>
                            <Divider
                                sx={{mb: 2, borderColor: 'primary.light', borderWidth: 2, maxWidth: 75}}/>
                            <Loading loading={loading} variant='rectangular' width='100%' height={300}>
                                <Box component='img' alt={gallery?.gallery_name}
                                     src={gallery?.images?.[0]?.full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}
                                     sx={{width: '100%', height: 300, objectFit: 'cover', bgcolor: '#f5f5f5'}}/>
                            </Loading>
                        </Box>
                    </Grid2>
                ))}
            </Grid2>
            {modal}
        </Box>
    );
}
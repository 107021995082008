import * as React from "react";
import Box from "@mui/material/Box";
import { Card, CardContent, Divider, Link, Typography } from "@mui/material";
import {
  Groups,
  CheckBox,
  Lock,
  Lightbulb,
  LinkOutlined,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Team from "../components/Team";

export function getValues() {
  return [
    {
      Icon: Groups,
      title: "Customer Service",
      color: "primary.dark",
      data: "To meet our client’s needs by providing professional services in a timely, accurate and cost-effective manner.",
    },
    {
      Icon: Lock,
      title: "Integrity",
      color: "primary.light",
      data: "To act honestly and respect the thoughts and ideas of our clients.",
    },
    {
      Icon: Lightbulb,
      title: "Innovation",
      color: "primary.main",
      data: "To be creative, innovative and embrace change.",
    },
  ];
}

export function getAboutUs(number: number = 3) {
  const paragrapghs = [
    <Typography
      key={0}
      variant="caption"
      sx={{ fontSize: "0.75rem", textAlign: "justify" }}
      component="p"
    >
      Civil One Consulting Engineers Ltd is a Kenyan{" "}
      <strong>ISO 9001:2015 Certified</strong> consulting engineering firm that
      started as a sole proprietorship but later registered as a limited
      company. Our mission is to satisfy Client needs by providing innovative,
      creative, feasible and quality solutions to engineering problems;
      solutions that are economical, socially desirable and environmentally
      sustainable. Both as a firm and individuals, we have been involved in a
      number of projects right from the design and formulation stage through
      construction to completion and final handover to the respective clients.
    </Typography>,
    <Typography
      key={1}
      variant="caption"
      sx={{ fontSize: "0.75rem", textAlign: "justify" }}
      component="p"
    >
      Some of the projects that the principal partners, who have also worked in
      reputable consulting firms and state parastatals, include: Water Supplies,
      Sanitation Services, factory buildings/godowns, Colleges/University,
      petrol stations, office blocks, medical facilities, road pavements, water
      works/pipelines, housing estates, exclusive residential maisonettes
      Schemes, apartments, industrial plants and banks. In consortium with
      reputable architects as well as other professionals, the firm offers
      professional, reliable and technologically compliant consultancy services.
    </Typography>,
    <Typography
      key={2}
      variant="caption"
      sx={{ fontSize: "0.75rem", textAlign: "justify" }}
      component="p"
    >
      The firm is registered as a legal entity (limited company) with the
      Registrar of Companies No. PVT-GYUG9RP. It is also registered by the
      Engineers Board of Kenya (EBK) as a consulting firm Registration No. ECF
      129 The firm’s offices are located at Signature Mall, Third Floor, Suite
      33 along Mombasa Road. The offices are well equipped with state-of-the-art
      computers and printers, and latest software for design and drafting.
    </Typography>,
  ];
  return paragrapghs.slice(0, number);
}

export default function About() {
  return (
    <Box>
      <Box
        component="img"
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: 500,
          objectFit: "cover",
          boxShadow: 4,
        }}
        src="https://www.civilone.co.ke/wp-content/uploads/2021/07/IMG_1795695.jpg"
      />
      <Box sx={{ my: 3 }}>
        <Link
          href="/about#about-us"
          id="about-us"
          sx={{
            display: "inline-flex",
            gap: 1,
            alignItems: "center",
            scrollMarginTop: 80,
            textDecoration: "none",
          }}
        >
          <Typography variant="h6">About Us</Typography>
          <LinkOutlined />
        </Link>
        <Divider
          sx={{
            mb: 2,
            borderColor: "primary.light",
            borderWidth: 2,
            maxWidth: 75,
          }}
        />

        <Grid2
          disableEqualOverflow={true}
          container
          spacing={5}
          alignItems="center"
        >
          <Grid2 xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
            <Box sx={{ bgcolor: "primary.light", p: 3, color: "#fff", mb: 3 }}>
              <Typography variant="h6" sx={{ textDecoration: "underline" }}>
                Vision
              </Typography>
              <Typography variant="body1" textAlign="justify">
                To become the No. 1, go-to engineering consultancy firm that
                provides a diverse range of engineering services to satisfy
                client needs
              </Typography>
            </Box>
            <Box sx={{ bgcolor: "primary.dark", p: 3, color: "#fff" }}>
              <Typography variant="h6" sx={{ textDecoration: "underline" }}>
                Mission
              </Typography>
              <Typography variant="body1" textAlign="justify">
                To provide high quality, knowledgeable and efficient engineering
                services to our clients.
              </Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            {getAboutUs()}
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ my: 3 }}>
        <Link
          href="/about#our-values"
          id="our-values"
          sx={{
            display: "inline-flex",
            gap: 1,
            alignItems: "center",
            scrollMarginTop: 80,
            textDecoration: "none",
          }}
        >
          <Typography variant="h6">Our Values</Typography>
          <LinkOutlined />
        </Link>
        <Divider
          sx={{
            mb: 2,
            borderColor: "primary.light",
            borderWidth: 2,
            maxWidth: 75,
          }}
        />
        <Grid2 disableEqualOverflow={true} container spacing={5}>
          {getValues().map((_, index) => (
            <Grid2 key={index} xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent
                  sx={{
                    bgcolor: _.color,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                    color: "#f5f5f5",
                    height: "100%",
                  }}
                >
                  <_.Icon fontSize="large" />
                  <Typography variant="h6" textTransform="uppercase">
                    {_.title}
                  </Typography>
                  <Divider
                    sx={{ borderColor: "#f5f5f5", opacity: 0.2, width: "100%" }}
                  />
                  <Typography variant="body2">
                    {_.data}
                  </Typography>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
      <Box
        sx={{
          my: 3,
          backgroundImage:
            "url(https://www.civilone.co.ke/wp-content/uploads/2021/05/scott-webb-kAJE-3_4zHE-unsplash-2-scaled.jpg)",
          bgcolor: "primary.main",
          p: 5,
          color: "#fff",
          backgroundBlendMode: "multiply",
        }}
        className="parallax"
      >
        <Grid2
          disableEqualOverflow={true}
          container
          spacing={3}
          alignItems="center"
          mb={3}
        >
          <Grid2 xs={12} sm={6}>
            <Link
              href="/about#what-we-do"
              id="what-we-do"
              sx={{
                display: "inline-flex",
                gap: 1,
                alignItems: "center",
                color: "white",
                scrollMarginTop: 135,
                textDecoration: 'none'
              }}
            >
              <Typography variant="h5">What we do</Typography>
              <LinkOutlined />
            </Link>

            <Divider
              sx={{
                mb: 2,
                borderColor: "white",
                borderWidth: 2,
                maxWidth: 75,
              }}
            />

            <Typography variant="body2" textAlign="justify">
              Currently, the firm has the capacity to undertake any form of
              Civil and Structural engineering analysis, planning, design and
              construction supervision. Civil One Consulting Engineers Ltd has a
              competent and professional team, which has experience in all
              aspects of Civil and Structural engineering and is up to date with
              modern construction trends. The high professional standards
              coupled with a creative and practical approach ensures a quick and
              efficient response in meeting the project objective by upholding
              structural integrity. At Civil One Consulting Engineers Ltd, we
              offer the following services
            </Typography>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              gap: 5,
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
              }}
            >
              <CheckBox fontSize="large" />
              <Typography
                variant="h2"
                sx={{ fontWeight: "400", color: "primary.light" }}
              >
                100
              </Typography>
              <Typography variant="h6">Projects Completed</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Groups fontSize="large" />
              <Typography
                variant="h2"
                sx={{ fontWeight: "400", color: "primary.light" }}
              >
                30
              </Typography>
              <Typography variant="h6">Customers</Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
      <Team />
    </Box>
  );
}

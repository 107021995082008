import {ApiResponse, FetchProps} from "./Types";
import {fetchFunction} from "./fetchFunction";

export type Gallery = {
    id: number;
    gallery_name: string;
    description: string;
    images: GalleryImage[];
}

export type GalleryImage = {
    id: number;
    full_image_url: string;
    caption: string | null;
}

export function fetchGallery({setLoading, setData, afterDone, fetchData}: FetchProps<Gallery[]>) {
    setLoading?.(true);
    fetchFunction<ApiResponse<Gallery[]>>({path: 'galleries', fetchData}).then(response => {
        setData(response.data);
        setLoading?.(false);
        afterDone?.(response);
    });
}
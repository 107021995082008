import * as React from "react";
import logo from '../logo.svg';
import Box from "@mui/material/Box";


const Logo = () => {
    const styles = {
        width: {xs: 60, sm: 65, md: 70},
        padding: 0,
        margin: 0,
    };
    return (
        <Box display='flex'>
            <Box component='img' src={logo} sx={styles}/>
        </Box>
    );
}

export default Logo;
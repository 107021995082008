import {ApiRequest} from "./Types";

export async function fetchFunction<T>({path, method, fetchData}: ApiRequest): Promise<T> {
    try {
        method = method || 'get';
        const {REACT_APP_API_KEY, REACT_APP_SERVER_URL, REACT_APP_TEAM_ID} = process.env;
        const queryString = fetchData && method === 'get' ? `?` + new URLSearchParams({...fetchData}) : '';
        const response = await fetch(`${REACT_APP_SERVER_URL}/${REACT_APP_TEAM_ID}/${path}${queryString}`, {
            method, ...(method !== 'get' && {body: JSON.stringify(fetchData)}),
            headers: {
                Authorization: `Bearer ${REACT_APP_API_KEY}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    } catch (e) {
        console.error(e);
        return Promise.resolve([] as T);
    }
}
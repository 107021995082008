import * as React from "react";
import {Service} from "../api/fetchServices";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {DEFAULTS} from "../common/Defaults";
import Loading, {LoadingProps} from "./Loading";
import {fetchProjects, Project} from "../api/fetchProjects";
import Moment from "react-moment";


export type AllProjectsProps = {
    setProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
    service?: Service;
}

export default function AllProjects({service, setProject}: AllProjectsProps) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Project[]>([]);
    React.useEffect(() => {
        setData(Array.from(Array(15)));
        fetchProjects({setLoading, setData, fetchData: service ? {service_id: service.id} : undefined});
    }, [service]);

    const tableCellLoadingStyles: LoadingProps = {loading, variant: 'rectangular', width: '100%', height: 35};

    return (
        <TableContainer component={Paper} sx={DEFAULTS.tableContainerMaxWidth}>
            <Table sx={DEFAULTS.tableContainerMaxWidth} size='small'>
                <TableHead>
                    <TableRow sx={{'& td, & th': {border: 1}}}>
                        <TableCell>Assignment</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Project Value</TableCell>
                        <TableCell>Associated Consultant</TableCell>
                        <TableCell>Assignment Period</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(!data || data.length === 0) && !loading && (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Typography variant='h6' sx={{textAlign: 'center', mt: 1}}>
                                    No Project Data Available!
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {data?.map((project, index) => (
                        <TableRow key={`project-all-${project?.id || index}`} sx={DEFAULTS.clickableTableRow}
                                  onClick={() => setProject(project)}>
                            <TableCell component="th" scope="row" sx={{maxWidth: 150}}>
                                <Loading {...tableCellLoadingStyles}>
                                    <Typography variant='body2'>{project?.project_name}</Typography>
                                </Loading>
                            </TableCell>
                            <TableCell>
                                <Loading {...tableCellLoadingStyles}>
                                    <Typography variant='body2'>{project?.client || 'N/A'}</Typography>
                                </Loading>
                            </TableCell>
                            <TableCell>
                                <Loading {...tableCellLoadingStyles}>
                                    <Typography variant='body2'>{project?.location || 'N/A'}</Typography>
                                </Loading>
                            </TableCell>
                            <TableCell>
                                <Loading {...tableCellLoadingStyles}>
                                    <Typography variant='body2'>
                                        {project?.total_value || 'N/A'}
                                    </Typography>
                                </Loading>
                            </TableCell>
                            <TableCell>
                                <Loading {...tableCellLoadingStyles}>
                                    <Typography variant='body2' dangerouslySetInnerHTML={{__html: project?.associated_consultants || 'N/A'}}/>
                                </Loading>
                            </TableCell>
                            <TableCell>
                                <Loading {...tableCellLoadingStyles}>
                                    <Box>
                                        <Typography variant='body2'>
                                            <strong style={{marginRight: '0.3rem'}}>Start Date:</strong>
                                            {project?.start_date ? <Moment interval={0} format={'MMMM, YYYY'}>{project.start_date}</Moment> : 'N/A'}
                                        </Typography>
                                        <Typography variant='body2'>
                                            <strong style={{marginRight: '0.3rem'}}>Completion Date:</strong>
                                            {project?.end_date ? <Moment interval={0} format={'MMMM, YYYY'}>{project.end_date}</Moment> : 'Ongoing'}
                                        </Typography>
                                    </Box>
                                </Loading>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
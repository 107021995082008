import * as React from 'react';
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {AccountTree, Close} from '@mui/icons-material';
import {blueGrey} from "@mui/material/colors";
import {Project} from "../api/fetchProjects";
import {DEFAULTS} from "../common/Defaults";

export default function ProjectBreadCrumb(project: Project & { closeModal: () => void }) {
    return (
        <Box display='flex' alignItems='center' gap={1}>
            {[
                {icon: <AccountTree/>, text: 'Projects'},
                {
                    icon: (
                        <Box component='img' sx={DEFAULTS.breadcrumbImage}
                             src={project.images?.[0]?.full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}/>
                    ),
                    text: project.project_name
                }].map(({icon, text}, index) => (
                <Typography variant='h6' key={`project-breadcrumb-${index}`}
                            sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Box style={{display: 'flex', alignItems: 'center', gap: 3, color: blueGrey[700]}}>
                        {icon}
                        {text}
                    </Box>
                    {index === 0 && (<Typography variant='body1' sx={{opacity: 0.3}}>-&gt;</Typography>)}
                </Typography>
            ))}
            <Box flexGrow={1}/>
            <IconButton onClick={project.closeModal}><Close/></IconButton>
        </Box>
    );
}
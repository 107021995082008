import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import * as React from "react";
import {fetchServices, Service} from "../api/fetchServices";
import Loading from "./Loading";

export type ServiceSelectProps = {
    onChange: (service?: Service) => void;
};

export function ServiceSelect({onChange}: ServiceSelectProps) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Service[]>([]);
    const [serviceID, setServiceID] = React.useState<number>(0);
    const afterDone = React.useCallback(() => {
        setData(prev => [{id: 0, service_name: 'All', description: 'All', images: []}, ...(prev || [])]);
        onChange(serviceID === 0 ? undefined : data?.[serviceID]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        setData([]);
        fetchServices({setLoading, setData, afterDone});
    }, [afterDone]);

    const onChangeEvent = (event: SelectChangeEvent<number>) => {
        setServiceID(_ => {
            const newServiceID = event.target.value as number;
            onChange(newServiceID === 0 ? undefined : data?.[newServiceID]);
            return newServiceID;
        });
    };
    return (
        <Loading loading={loading} variant='rounded' width='100%' height={60} sx={{my: 3}}>
            <FormControl fullWidth sx={{my: 3}}>
                <InputLabel id="service-select">Service</InputLabel>
                <Select
                    labelId="service-select"
                    value={serviceID}
                    label="Service"
                    onChange={onChangeEvent}
                >
                    {data.map((service, index) => (
                        <MenuItem key={`service-select-option-${service?.id || index}`}
                                  value={index}>{service?.service_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Loading>
    );
}
import * as React from 'react';
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Project as ProjectData } from "../api/fetchProjects";
import Moment from 'react-moment';

export default function Project(project: ProjectData) {
    return (
        <Box>
            <Typography variant='h6'
                sx={{ px: 1, my: 1, color: 'white', bgcolor: 'primary.dark', borderRadius: 1 }}>
                Contract Value of Project - KES. 59,193,578.00
            </Typography>
            <Grid2 disableEqualOverflow={true} container spacing={5}>
                <Grid2 xs={12} sm={6}>
                    <Box sx={{ bgcolor: 'grey.300', p: 3, borderRadius: 1 }}>
                        <Typography variant='body2' mb={2}>
                            <strong>Client: </strong>
                            {project.client || 'N/A'}
                        </Typography>
                        <Typography variant='body2' mb={2}>
                            <strong>Location: </strong>
                            {project.location || 'N/A'}
                        </Typography>

                        <Typography variant='body2' mb={2}>
                            <strong style={{ marginRight: '0.3rem' }}>Year Started:</strong>
                            {project.start_date ? <Moment interval={0} format={'MMMM, YYYY'}>{project.start_date}</Moment> : 'N/A'}
                        </Typography>

                        <Typography variant='body2' mb={2}>
                            <strong style={{ marginRight: '0.3rem' }}>Year Completed:</strong>
                            {project.end_date ? <Moment interval={0} format={'MMMM, YYYY'}>{project.end_date}</Moment> : 'Ongoing'}
                        </Typography>

                        <Typography variant='body2' mb={5}>
                            <strong>Value: </strong>
                            KES. {project.total_value || 'N/A'}
                        </Typography>

                        <Typography variant='body1' fontWeight={700} mb={1}>Name of Associated Consultant:</Typography>

                        <Typography variant='body2' mb={2} dangerouslySetInnerHTML={{ __html: project.associated_consultants || 'No Associated Consultants for this Project!' }} />


                        <Typography variant='body1' fontWeight={700} mb={1}>Professional Staff Provided by our Firm:</Typography>

                        <Typography variant='body2' mb={1} dangerouslySetInnerHTML={{ __html: project.staff_provided || 'No Staff Provided by our Firm!' }} />
                    </Box>
                    <Typography variant='body2' my={2} dangerouslySetInnerHTML={{ __html: project.brief || 'No Brief for this Project!' }} />
                    {project.services_provided && (<>
                        <Typography variant='subtitle1' sx={{ textDecoration: 'underline' }} mb={1}>
                            Description of Actual Services Provided by Our Staff:
                        </Typography>
                        <Typography variant='body2'
                            dangerouslySetInnerHTML={{ __html: project.services_provided }} />
                    </>)}
                </Grid2>
                <Grid2 xs={12} sm={6} mt={1}>
                    {project.images.map(image => (
                        <Box component='img' src={image.full_image_url} key={`project-image-${image.id}`}
                            sx={{ width: '100%', height: 'auto', maxHeight: 660, boxShadow: 1, my: 1 }} />
                    ))}
                    {project.images.length === 0 &&
                        <Box component='img'
                            src='https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'
                            sx={{ width: '100%', height: 'auto', maxHeight: 660, boxShadow: 1, my: 1 }} />}
                </Grid2>
            </Grid2>
        </Box>
    );
}

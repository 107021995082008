import * as React from 'react';
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {Close, Info} from '@mui/icons-material';
import {blueGrey} from "@mui/material/colors";
import {Team} from "../api/fetchTeam";
import {DEFAULTS} from "../common/Defaults";

export default function TeamMemberBreadCrumb(teamMember: Team & { closeModal: () => void }) {
    return (
        <Box display='flex' alignItems='center' gap={1}>
            {[
                {icon: <Info/>, text: 'Who we Are'},
                {
                    icon: (
                        <Box component='img' sx={DEFAULTS.breadcrumbImage}
                             src={teamMember.full_profile_photo_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'}/>
                    ),
                    text: teamMember.full_name
                }].map(({icon, text}, index) => (
                <Typography variant='h6' key={`team-member-breadcrumb-${index}`}
                            sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Box style={{display: 'flex', alignItems: 'center', gap: 3, color: blueGrey[700]}}>
                        {icon}
                        {text}
                    </Box>
                    {index === 0 && (<Typography variant='body1' sx={{opacity: 0.3}}>-&gt;</Typography>)}
                </Typography>
            ))}
            <Box flexGrow={1}/>
            <IconButton onClick={teamMember.closeModal}><Close/></IconButton>
        </Box>
    );
}
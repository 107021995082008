import {ApiResponse, FetchProps} from "./Types";
import {fetchFunction} from "./fetchFunction";

export interface Service {
    id: number;
    service_name: string;
    description: string | null;
    images: ServiceImage[];
}

export type ServiceImage = {
    id: number;
    full_image_url: string;
}

export function getDescription(service: Service) {
    if(!service?.description) return 'No Description has been added for this Service!';
    return service.description.substring(0, 100) + (service.description.length > 100 ? '. . .' : '');
}

export function fetchServices({setLoading, setData, afterDone, fetchData}: FetchProps<Service[]>) {
    setLoading?.(true);
    fetchFunction<ApiResponse<Service[]>>({path: 'services', fetchData}).then(response => {
        setData(response.data);
        setLoading?.(false);
        afterDone?.(response);
    });
}
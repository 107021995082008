import * as React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Paper,
    TableRow,
    Button,
    Typography, Link, Box, lighten
} from "@mui/material";
import {FileDownload, FileUpload} from "@mui/icons-material";
import {DEFAULTS} from "../common/Defaults";


export default function Downloads() {
    const iconStyle = {fontSize: 14, border: 1, borderRadius: '50%', borderColor: lighten('#000', 0.6), p: 0.08};
    const data = [
        {title: 'Company Profile 2022', link: 'https://www.civilone.co.ke/wp-content/uploads/2022/06/Civil-One-Company-Consulting-Engineers-Ltd-Profile-2022.pdf', downloads: '102', date: 'October 26, 2022'},
        {title: 'Quality Policy Statement', link: 'https://www.civilone.co.ke/download/quality-policy-statement/?wpdmdl=3080&refresh=63a0bbd36222a1671478227', downloads: '17', date: 'March 15, 2022'},
    ];
    return (
        <TableContainer component={Paper} sx={DEFAULTS.tableContainerMaxWidth}>
            <Table sx={DEFAULTS.tableContainerMaxWidth} aria-label="simple table" size='small'>
                <TableHead sx={{'& td, & th': {border: 1}}}>
                    <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Categories</TableCell>
                        <TableCell>Download</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => (
                        <TableRow key={i} sx={DEFAULTS.tableRow}>
                            <TableCell component="th" scope="row">
                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                    <Typography variant='subtitle1'>
                                        <Link href={row.link}>
                                            {row.title}
                                        </Link>
                                    </Typography>
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                        <FileUpload sx={iconStyle}/>
                                        <Typography variant='caption' mr={2}>
                                            1
                                        </Typography>
                                        <FileDownload sx={iconStyle}/>
                                        <Typography variant='caption'>
                                            {row.downloads} Downloads
                                        </Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box component='img' width={50}
                                     src='https://www.civilone.co.ke/wp-content/plugins/download-manager/assets/file-type-icons/pdf.svg'/>
                            </TableCell>
                            <TableCell>
                                <Link href={row.link} download>
                                    <Button variant='contained'>Download</Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export const DEFAULTS = {
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '85%'},
        maxWidth: 1000,
        maxHeight: 'calc(100vh - 3rem)',
        bgcolor: 'background.paper',
        border: '1px solid #eee',
        boxShadow: 24,
        p: 2,
        outline: 'none',
        overflow: 'auto'
    },
    tableContainerMaxWidth: {maxWidth: 'calc(100vw - 2rem)'},
    tableRow: {'& td, & th': {border: 1, fontSize: '0.7rem'}, '& td span, & th span': {fontSize: '0.7rem'}},
    clickableTableRow: {
        cursor: 'pointer',
        '& td, & th': {border: 1},
        '& td p, & th p': {fontSize: '0.7rem'},
        '&:hover': {bgcolor: 'grey.50'},
        '&:active': {bgcolor: 'grey.200'},
    },
    breadcrumbImage: {
        width: 30,
        mr: 0.3,
        height: 30,
        objectFit: 'cover',
        borderRadius: '50%',
        border: 1,
        borderColor: 'grey.400'
    }
}
import * as React from "react";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TextField, Typography, Button, Alert, Divider } from "@mui/material";
import {
  Mail,
  Person,
  Send,
  Subject,
  SvgIconComponent,
  Title,
} from "@mui/icons-material";
import Loading from "../components/Loading";
import {
  Contact,
  fetchContacts,
  Email,
  sendEmail,
  EmailResponse,
} from "../api/fetchContacts";

type FormControl = {
  label: string;
  key: keyof Email;
  helperText: string;
  Icon: SvgIconComponent;
};

function defaultEmailData(): Email {
  return {
    name: "",
    email: "",
    subject: "General Enquiry",
    message: "",
  };
}

export default function ContactUs() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Contact[]>([]);
  React.useEffect(() => {
    setData([]);
    fetchContacts({ setLoading, setData });
  }, []);

  const [emailData, setEmailData] = React.useState<Email>(defaultEmailData());
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<EmailResponse>();
  const formControl: FormControl[] = [
    {
      label: "Full Name",
      key: "name",
      helperText: "e.g John Doe",
      Icon: Person,
    },
    {
      label: "Email address",
      key: "email",
      helperText: "e.g johndoe@example.com",
      Icon: Mail,
    },
    {
      label: "Subject",
      key: "subject",
      helperText: "e.g Enquiry on Construction Architecture",
      Icon: Title,
    },
    {
      label: "Your Message",
      key: "message",
      helperText: "Write whatever you want . . .",
      Icon: Subject,
    },
  ];

  const handleInputChange =
    (key: keyof Email) => (event: React.SyntheticEvent) =>
      setEmailData((prev) => ({
        ...prev,
        [key]: (event.target as HTMLInputElement).value,
      }));
  const handleSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      sendEmail({
        setSubmitting,
        setResponse,
        body: emailData,
        afterDone: (response) =>
          response.success && setEmailData(defaultEmailData()),
      });
    },
    [emailData]
  );

  return (
    <Box>
      <Box
        component="img"
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: 500,
          objectFit: "cover",
          boxShadow: 4,
        }}
        src="https://www.civilone.co.ke/wp-content/uploads/2021/06/IMG_1713-scaled.jpg"
      />
      <Grid2
        disableEqualOverflow={true}
        container
        spacing={5}
        alignItems="center"
        mt={1}
      >
        <Grid2 xs={12} md={6}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box>
                <Typography variant="h6">Physical Address</Typography>
                <Divider
                  sx={{
                    mb: 2,
                    borderColor: "primary.light",
                    borderWidth: 2,
                    maxWidth: 75,
                  }}
                />
              </Box>
              <Loading repeat={3} loading={loading}>
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{
                    __html:
                      data
                        ?.filter(({ contact_type_id }) => contact_type_id === 8)
                        ?.map(({ contact }) =>
                          contact.replaceAll(",", ",<br/>")
                        )
                        .join("<br/><br/>") || "No Physical Location Info!",
                  }}
                ></Typography>
              </Loading>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box>
                <Typography variant="h6">Contact Info</Typography>
                <Divider
                  sx={{
                    mb: 2,
                    borderColor: "primary.light",
                    borderWidth: 2,
                    maxWidth: 75,
                  }}
                />
              </Box>
              <Loading loading={loading}>
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{
                    __html:
                      data
                        ?.filter(({ contact_type_id }) => contact_type_id === 1)
                        ?.map(({ contact }) => contact)
                        .join(", ") || "No Phone Contact Info!",
                  }}
                ></Typography>
              </Loading>
              <Loading loading={loading}>
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{
                    __html:
                      data
                        ?.filter(({ contact_type_id }) => contact_type_id === 2)
                        ?.map(({ contact }) => contact)
                        .join(", ") || "No Email Contact Info!",
                  }}
                ></Typography>
              </Loading>
              <Typography variant="caption">https://civilone.co.ke</Typography>
            </Box>
          </Box>
          <Box
            component="iframe"
            mt={3}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15954.39254193523!2d36.94468873711203!3d-1.4170492834643433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0b355716bf25%3A0xe1c1d3e8a9552300!2sSignature%20Mall!5e0!3m2!1sen!2ske!4v1668721110920!5m2!1sen!2ske"
            width="100%"
            height={450}
            border={0}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></Box>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Typography variant="body2" fontStyle="italic" fontWeight={500}>
            Call or submit our online form to request an estimate or for general
            questions about our services. We look forward to serving you!
          </Typography>
          {response && (
            <Alert
              severity={response.success ? "success" : "error"}
              sx={{ my: 1 }}
            >
              {response.success
                ? "Email has been Successfully Sent. We will reach out to you soon!"
                : response.message}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} sx={{ my: 3 }}>
            {formControl.map(({ label, key, helperText, Icon }) => (
              <TextField
                key={key}
                label={label}
                error={response?.errors?.[key] !== undefined}
                helperText={response?.errors?.[key] ?? helperText}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={emailData[key]}
                onChange={handleInputChange(key)}
                InputProps={{
                  startAdornment: <Icon sx={{ color: "gray", mr: 1 }} />,
                }}
              />
            ))}
            <Button
              type="submit"
              disabled={submitting}
              variant="contained"
              sx={{ float: "right", opacity: submitting ? 0.6 : 1 }}
              endIcon={<Send />}
            >
              Submit
            </Button>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}

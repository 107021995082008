import React from "react";
import {Box, BoxProps} from "@mui/material";

export type TabContentProps = {
    currentTabIndex: number;
    index: number;
    children: JSX.Element | JSX.Element[];
}

export default function TabContent({currentTabIndex, children, index, ...boxProps}: TabContentProps & BoxProps) {
    return (
        <Box hidden={currentTabIndex !== index} {...boxProps}>{children}</Box>
    );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { ListItemIcon, MenuItem, Menu, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Logo from "./Logo";
import { NavLink, Link } from "react-router-dom";
import { useRoutes } from '../App';
import { grey } from '@mui/material/colors';

export type DropdownData = {
    target: HTMLButtonElement | HTMLLIElement | null;
    data: ReturnType<typeof useRoutes>[0]['hashNavigation'];
};

const drawerWidth = '25ch';

export default function NavigationBar() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [dropdownData, setDropdownData] = React.useState<DropdownData>();
    const [accordion, setAccordion] = React.useState<number>();

    const toggleAccordion = (index: number) => (event: React.SyntheticEvent, newExpanded: boolean) => setAccordion(newExpanded ? index : undefined);

    React.useEffect(() => {
        document.querySelector('main')?.removeEventListener('mouseover', () => setDropdownData(undefined))
        document.querySelector('main')?.addEventListener('mouseover', () => setDropdownData(undefined))
    }, []);

    const accordionLink = ({ path, Icon, title, hashNavigation }: ReturnType<typeof useRoutes>[0], index: number) => {
        const navLink = (
            <NavLink to={path} onClick={() => { setAccordion(index); setMobileOpen(!mobileOpen) }}
                style={{ color: 'inherit', width: "100%", textDecoration: 'none' }}>
                <ListItemButton>
                    <ListItemIcon><Icon /></ListItemIcon>
                    <ListItemText primary={title} />
                </ListItemButton>
            </NavLink>
        );
        if (!hashNavigation?.length) return navLink;
        return (
            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ borderBottom: 0, width: '100%', boxShadow: 0 }} expanded={accordion === index} onChange={toggleAccordion(index)}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ p: 0, pr: 1, '& .MuiAccordionSummary-content': { m: 0 } }} >{navLink}</AccordionSummary>
                <AccordionDetails sx={{ py: 0 }}>
                    <List sx={{ py: 0, maxHeight: 200, overflow: 'auto' }}>
                        {hashNavigation.map(({ title, path }, index) => (
                            <NavLink to={path} onClick={() => setMobileOpen(!mobileOpen)} key={index}
                                style={{ color: 'inherit', width: "100%", textDecoration: 'none' }}>
                                <ListItemButton>
                                    <ListItemText primaryTypographyProps={{ noWrap: true, variant: 'body2', title, sx: { color: 'grey.700' } }} primary={title} />
                                </ListItemButton>
                            </NavLink>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <Box display="flex">
            <AppBar onClick={() => mobileOpen && setMobileOpen(false)} component="nav" position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar sx={{ gap: 2, alignItems: 'center' }}>
                    <Link to='/' style={{ flexGrow: 1 }}>
                        <Logo />
                    </Link>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        {useRoutes().map(({ title, path, hashNavigation }) => (
                            <Button key={path} sx={{ color: '#f5f5f5', mr: 3 }}
                                onMouseOver={(event) => setDropdownData({ target: event.currentTarget, data: hashNavigation })}>
                                <NavLink to={path} style={{ color: '#f5f5f5', textDecoration: 'none' }}>
                                    {title}
                                </NavLink>
                            </Button>
                        ))}
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => setMobileOpen(!mobileOpen)}
                        sx={{ display: { lg: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <SwipeableDrawer
                    anchor='right'
                    variant="temporary"
                    open={mobileOpen}
                    onOpen={() => setMobileOpen(!mobileOpen)}
                    onClose={() => setMobileOpen(!mobileOpen)}
                    ModalProps={{ keepMounted: true }}
                    disableBackdropTransition
                    PaperProps={{ sx: { boxSizing: 'border-box', width: drawerWidth } }}
                    sx={{ display: { xs: 'block', lg: 'none' } }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Toolbar />
                        <List>
                            {useRoutes().map(({ title, ...rest }, index) => (
                                <ListItem key={title} disablePadding>{accordionLink({ title, ...rest }, index)}</ListItem>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Box>
            <Menu
                variant='menu'
                PaperProps={{ sx: { mt: 1, maxHeight: 200, pointerEvents: 'auto' } }}
                sx={{ pointerEvents: 'none' }}
                anchorEl={dropdownData?.target}
                open={Boolean(dropdownData?.data?.length)}
                onClose={() => setDropdownData(undefined)}>
                {dropdownData?.data?.map(({ title, path }, index) => (
                    <MenuItem onClick={() => setDropdownData(undefined)} key={index}>
                        <NavLink to={path} style={{ color: grey[800], textDecoration: 'none' }}>
                            {title}
                        </NavLink>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}


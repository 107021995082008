import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { Service } from "../api/fetchServices";
import Loading from "./Loading";
import { fetchProjects, Project } from "../api/fetchProjects";

export type FeaturedProjectsProps = {
    setProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
    service?: Service;
    limit?: number;
    parentLoading?: boolean;
    parentData?: Project[];
}

export default function FeaturedProjects({ setProject, service, limit = 10, parentData, parentLoading }: FeaturedProjectsProps) {
    const isDashboard = parentLoading !== undefined;
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Project[]>([]);

    React.useEffect(() => {
        setData(parentLoading || !isDashboard ? Array.from(Array(limit)) : parentData || []);

        if (isDashboard) setLoading(parentLoading); else {
            fetchProjects({ setLoading, setData, fetchData: { limit, ...(service && { service_id: service.id }) } });
        }
    }, [service, parentLoading, parentData, isDashboard, limit]);

    const grid = {
        xs: isDashboard ? 6 : 12,
        sm: isDashboard ? 4 : 6,
        md: isDashboard ? 3 : 4,
        lg: isDashboard ? 2 : 3,
    }

    const after = {
        content: "''",
        bgcolor: 'rgba(25,118,210,0.75)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0, left: 0,
        cursor: 'pointer',
        borderRadius: 2,
    };

    return (
        <Box>
            {(!data || data.length === 0) && !loading && (
                <Typography variant='h6' sx={{ textAlign: 'center', my: 3 }}>No Featured Projects Available!</Typography>
            )}
            <Grid2 disableEqualOverflow={true} container justifyContent='center' spacing={isDashboard ? 1 : 5}>
                {data?.map((project, index) => (
                    <Grid2 {...grid} key={`project-featured-${project?.id || index}`} sx={{'&:hover p': {fontWeight: 700}, '&:hover img': {borderRadius: 2}, '&:hover section': {borderRadius: 2, boxShadow: 10,'&:after': after}}}>
                        <Box sx={{position: 'relative', height: isDashboard ? 200 : 300}} component='section' onClick={() => !loading && setProject(project)}>
                            <Loading loading={loading} variant='rectangular' width='100%' height={isDashboard ? 200 : 300}>
                                <Box component='img'
                                    sx={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer'}}
                                    src={project?.images?.[0]?.full_image_url || 'https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png'} />
                            </Loading>
                        </Box>
                        <Loading loading={loading} width='100%' sx={{ fontSize: '1.5rem', mt: 1 }}>
                            <Typography noWrap={!isDashboard} onClick={() => setProject(project)} title={project?.project_name} component='p' variant='overline'
                                sx={{ color: 'grey.700', lineHeight: "normal", cursor: 'pointer' }} mt={1}>
                                {project?.project_name}
                            </Typography>
                        </Loading>
                        {!isDashboard && 
                        <Loading loading={loading} width='100%' sx={{ fontSize: '1.5rem' }}>
                            <Typography component='p' variant='overline' sx={{ color: 'grey.800' }} fontWeight={700}>
                                {project?.location || 'N/A'}
                            </Typography>
                        </Loading>}
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    )
}
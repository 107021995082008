import { Divider, Link, Modal, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import * as React from "react";
import { DEFAULTS } from "../common/Defaults";
import TeamMemberBreadCrumb from "./TeamMemberBreadCrumb";
import TeamMember from "./TeamMember";
import Loading from "./Loading";
import { fetchTeam, Team as TeamData } from "../api/fetchTeam";
import { LinkOutlined } from "@mui/icons-material";

export default function Team() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<TeamData[]>([]);
  const [teamMemberID, setTeamMemberID] = React.useState<number>();
  React.useEffect(() => {
    setData(Array.from(Array(6)));
    fetchTeam({ setLoading, setData });
  }, []);

  const modal = (
    <Modal
      open={teamMemberID !== undefined}
      onClose={() => setTeamMemberID(undefined)}
    >
      <Box sx={DEFAULTS.modalStyle}>
        {teamMemberID !== undefined && data[teamMemberID] && (
          <TeamMemberBreadCrumb {...data[teamMemberID]} closeModal={() => setTeamMemberID(undefined)} />
        )}
        <Divider sx={{ mt: 2 }} />
        {teamMemberID !== undefined && data[teamMemberID] && (
          <TeamMember {...data[teamMemberID]} />
        )}
      </Box>
    </Modal>
  );

  return (
    <Box sx={{ my: 3 }}>
      <Link
        href="/about#our-team"
        id="our-team"
        sx={{
          display: "inline-flex",
          gap: 1,
          alignItems: "center",
          scrollMarginTop: 80,
          textDecoration: 'none'
        }}
      >
        <Typography variant="h6">Our Team</Typography>
        <LinkOutlined />
      </Link>
      <Divider
        sx={{
          mb: 4,
          borderColor: "primary.light",
          borderWidth: 2,
          maxWidth: 75,
        }}
      />

      {(!data || data.length === 0) && !loading && (
        <Typography variant="h6" sx={{ textAlign: "center", my: 3 }}>
          No Team Members Available!
        </Typography>
      )}
      <Grid2 disableEqualOverflow={true} container spacing={10}>
        {data?.map((teamMember, index) => (
          <Grid2
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={`team-member-${teamMember?.id || index}`}
          >
            <Loading
              loading={loading}
              variant="rectangular"
              width={250}
              height={300}
            >
              <Box
                onClick={() => setTeamMemberID(index)}
                component="img"
                sx={{
                  width: 250,
                  height: 300,
                  objectFit: "cover",
                  boxShadow: 4,
                  cursor: "pointer",
                }}
                src={
                  teamMember?.full_profile_photo_url ||
                  "https://res.cloudinary.com/dkgtd3pil/image/upload/v1594283536/other_data/no-image.png"
                }
              />
            </Loading>
            <Loading loading={loading} sx={{ fontSize: "1.5rem", mt: 1 }}>
              <Typography
                onClick={() => setTeamMemberID(index)}
                variant="subtitle1"
                className="hoveredLink"
                lineHeight={1}
                mt={1}
                fontWeight={500}
                sx={{ color: "primary.main", cursor: "pointer" }}
              >
                {teamMember?.full_name}
              </Typography>
            </Loading>
            <Loading loading={loading} width="50%">
              <Typography variant="overline" sx={{lineHeight: 'normal'}}>
                {teamMember?.role || "Member"}
              </Typography>
            </Loading>
          </Grid2>
        ))}
      </Grid2>
      {modal}
    </Box>
  );
}

import {ApiResponse, FetchProps} from "./Types";
import {fetchFunction} from "./fetchFunction";

export type Project = {
    id: number;
    project_name: string;
    client: string;
    images: ProjectImage[];

    location: string | null;
    start_date: string | null;
    end_date: string | null;
    total_value: number | null;
    associated_consultants: string | null;
    staff_provided: string | null;
    brief: string | null;
    services_provided: string | null;
}

export type ProjectImage = {
    id: number;
    full_image_url: string;
}

export function fetchProjects({setLoading, setData, afterDone, fetchData}: FetchProps<Project[]>) {
    setLoading?.(true);
    fetchFunction<ApiResponse<Project[]>>({path: 'projects', fetchData}).then(response => {
        setData(response.data);
        setLoading?.(false);
        afterDone?.(response);
    });
}
import * as React from 'react';
import { Box, Divider, Modal, Tabs, Tab } from "@mui/material";
import { DEFAULTS } from "../common/Defaults";
import TabContent from "../common/TabContent";
import ProjectBreadCrumb from "../components/ProjectBreadCrumb";
import Project from "../components/Project";
import { ServiceSelect } from "../components/ServiceSelect";
import { Service } from "../api/fetchServices";
import { Project as ProjectData } from "../api/fetchProjects";
import FeaturedProjects from "../components/FeaturedProjects";
import AllProjects from "../components/AllProjects";

export default function Projects() {
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const [project, setProject] = React.useState<ProjectData>();
    const [service, setService] = React.useState<Service>();

    const modal = (<Modal
        open={project !== undefined}
        onClose={() => setProject(undefined)}
    >
        <Box sx={DEFAULTS.modalStyle}>
            {project !== undefined && <ProjectBreadCrumb {...project} closeModal={() => setProject(undefined)} />}
            <Divider sx={{ mt: 2 }} />
            {project !== undefined && <Project {...project} />}
        </Box>
    </Modal>);

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant='fullWidth' value={tabIndex} onChange={(_, val) => setTabIndex(val)}>
                    <Tab label="Featured Projects" href='/projects#featured-projects' id='featured-projects' sx={{ scrollMarginTop: 80 }} />
                    <Tab label="All Projects" href='/projects#all-projects' id='all-projects' sx={{ scrollMarginTop: 80 }} />
                </Tabs>
            </Box>
            <ServiceSelect onChange={setService} />
            <TabContent currentTabIndex={tabIndex} index={0}>
                <FeaturedProjects service={service} setProject={setProject} />
            </TabContent>
            <TabContent currentTabIndex={tabIndex} index={1}>
                <AllProjects service={service} setProject={setProject} />
            </TabContent>
            {modal}
        </Box>
    );
}
import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";

export function getClients() {
    return [
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/East-African-Breweries-Ltd.png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/BL-Management-Limited.png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/British-American-Tobacco-Kenya.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Kiri-Consult-Ltd.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Getso-Consultants-Limited.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/County-Government-of-Marsabit.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Precise-Architects-Ltd.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Symbion.png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Architecture-by-Cedar-Ltd..png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Opal-Infrastructure-Services-Ltd.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Meru-National-Polytechnic.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/jiik.jpg',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Kenya-Coast-National.png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/geumken.png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Kenya-National-Highways-Authority-KeNHA.png',
        'https://www.civilone.co.ke/wp-content/uploads/2021/05/Juja-City-Mall-Ltd.jpg'
    ];
}

export default function Clients() {
    return (
        <Box display='flex' justifyContent='center'>
            <Grid2 disableEqualOverflow={true} container sx={{ maxWidth: { sm: '70%' } }}>
                {getClients().map((src, i) => (
                    <Grid2 xs={12} sm={6} md={4} lg={3} border={1} key={i}>
                        <Box component='img' src={src} sx={{ width: '100%', height: 100, objectFit: 'contain' }} />
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    );
}
import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AccountTree, Download, Home, Info, Mail, People, PhotoLibrary, Settings } from "@mui/icons-material";
import SkeletonComponent from "./pages/Skeleton";
import AboutComponent from "./pages/About";
import HomeComponent from "./pages/Home";
import ServicesComponent from "./pages/Services";
import ContactUsComponent from "./pages/ContactUs";
import ProjectsComponent from "./pages/Projects";
import GalleryComponent from "./pages/Gallery";
import ClientsComponent from "./pages/Clients";
import DownloadsComponent from "./pages/Downloads";
import { fetchServices, Service } from './api/fetchServices';

export const useRoutes = () => {
    const [data, setData] = React.useState<Service[]>([]);
    React.useEffect(() => {
        setData([]);
        fetchServices({ setData });
    }, []);

    const routes = [
        { title: 'Home', Icon: Home, path: '/', element: <HomeComponent /> },
        {
            title: 'Who We Are', Icon: Info, path: '/about', element: <AboutComponent />, hashNavigation: [
                { title: 'About Us', path: '/about#about-us' },
                { title: 'Our Values', path: '/about#our-values' },
                { title: 'What we do', path: '/about#what-we-do' },
                { title: 'Our Team', path: '/about#our-team' },
            ]
        },
        { title: 'Our Services', Icon: Settings, path: '/services', element: <ServicesComponent />, hashNavigation: data?.map(({ service_name }, index) => ({ title: service_name, path: `/services#service-id-${index}` })) || undefined },
        {
            title: 'Projects', Icon: AccountTree, path: '/projects', element: <ProjectsComponent />, hashNavigation: [
                { title: 'Featured Projects', path: '/projects#featured-projects' },
                { title: 'All Projects', path: '/projects#all-projects' },
            ]
        },
        { title: 'Gallery', Icon: PhotoLibrary, path: '/gallery', element: <GalleryComponent /> },
        { title: 'Clientele', Icon: People, path: '/clients', element: <ClientsComponent /> },
        { title: 'Downloads', Icon: Download, path: '/downloads', element: <DownloadsComponent /> },
        { title: 'Contact Us', Icon: Mail, path: '/contact', element: <ContactUsComponent /> },
    ];

    return routes;
}


function App() {
    return (
        <RouterProvider router={createBrowserRouter([
            {
                element: <SkeletonComponent />,
                children: useRoutes().map(({ path, element }) => ({ path, element })),
            }
        ])} />
    );
}

export default App;

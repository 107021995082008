import { matchPath, Outlet, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import NavigationBar from "../components/NavigationBar";
import Toolbar from "@mui/material/Toolbar";
import { Divider, Link, Typography } from "@mui/material";
import React from "react";
import { useRoutes } from '../App';
import { SvgIconComponent } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
import moment from "moment";
import { Contact, fetchContacts, getLink, Icon } from "../api/fetchContacts";
import Loading from "../components/Loading";

type BreadCrumb = { Icon?: SvgIconComponent, title?: string, path: string, breadCrumb?: React.ReactNode }[];

function updateBreadCrumbArray(
    pathname: string,
    setBreadCrumbArray: React.Dispatch<React.SetStateAction<BreadCrumb>>,
    root: any[],
): void {
    for (let i = 0; i < 2; i++) {
        let { Icon = null, title = null, children = [], path, breadCrumb } = root.find(({ path, children }) => matchPath({
            path,
            end: children === undefined
        }, pathname)) ?? {};
        if (path && ((Icon && title) || breadCrumb)) setBreadCrumbArray((initial) => [...initial, {
            Icon,
            title,
            path: children.length > 0 ? path : pathname,
            breadCrumb
        }]);

        if (children.length === 0) break;
        root = children;
    }
}

function Skeleton() {
    const routes = useRoutes();
    const { pathname, hash } = useLocation();
    const [breadCrumbArray, setBreadCrumbArray] = React.useState<BreadCrumb>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<Contact[]>([]);

    React.useEffect(() => {
        setData(Array.from(Array(4)));
        fetchContacts({ setLoading, setData });
    }, []);

    React.useEffect(() => {
        setBreadCrumbArray([]);
        updateBreadCrumbArray(pathname, setBreadCrumbArray, routes);
        if (hash) setTimeout(() => document.getElementById(hash.substring(1))?.click(), 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash, pathname, setBreadCrumbArray]);

    return (
        <Box>
            <NavigationBar />
            <Box component="main" sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Toolbar />
                <Box sx={{ p: 2 }}>
                    <Box display='flex' gap={1} alignItems='center'>
                        {breadCrumbArray.map(({ Icon, title, path, breadCrumb }, index) => (
                            <Typography key={title} variant='h6'
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Link href={path} className='hoveredLink'
                                    sx={{ display: 'flex', alignItems: 'center', gap: 3, color: blueGrey[700] }}>
                                    {Icon ? <Icon /> : breadCrumb}
                                    {breadCrumb ? null : title}
                                </Link>
                                {index + 1 !== breadCrumbArray.length &&
                                    <Typography variant='body1' sx={{ opacity: 0.3 }}>-&gt;</Typography>}
                            </Typography>
                        ))}
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Outlet />
                </Box>
                <Box flexGrow={1} />
                <Box component='footer' sx={{ bgcolor: 'grey.300', color: 'grey.800', px: 2, display: 'flex', gap: 3, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box display='flex' alignItems='center'>
                        <Box component='img' src='logo192.png' sx={{ width: 60 }} />
                        <Typography variant="overline" fontWeight={700} display={{ xs: 'none', md: 'block' }}>Civil One <small>(Consulting Engineers)</small></Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={5}>
                        {data?.filter((contact) => [1, 2, 8].indexOf(contact?.contact_type_id) === -1)?.map((contact, index) => (
                            <Loading loading={loading} variant='circular' width={35} height={35} key={contact?.id || index}>
                                <Link href={contact ? getLink(contact) : ''} sx={{ color: 'grey.700' }}>
                                    <Icon {...contact} />
                                </Link>
                            </Loading>
                        ))}
                    </Box>
                    <Typography variant="overline" fontWeight={700}>Copyright &copy; {moment().format('YYYY')}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default Skeleton;